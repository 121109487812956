import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { projectLogo} from '../../../resources/constants';


function PrintModule(props) {
    const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [moduleSemesterList, setModuleSemesterList] = useState([]);
    const [total, setTotal] = useState(0)

    const studentID = props.loginData[0].StudentID
    const currentSemester = props.currentSemester

    const getData = async () => {
        axios.post(`${serverLink}students/registration/print/registered/module`, { studentID: studentID }, token).then((result) => {
            if(result.data.length > 0){
                setModuleSemesterList(result.data)
                setTotal(result.data.map(e=>parseInt(e.CreditLoad)).reduce((a,b)=>a+b,0))
            } 
            setIsLoading(false)
        })
       

    }
    const userData = props.loginData[0];

    const printNow = () => {
        window.print();
    }

    useEffect(() => {
        getData();
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Print Registered Modules"}
                items={["Student Registration", "Registration", "Print Registered Modules"]}
            />
             <div className="myDivToPrint" style={{overflow:"auto"}}>
                 <div className={"text-center mb-5"}>
                     <img src={projectLogo} alt="" className="imgCenter" width={100} height={100}/>
                 </div>
                 <h4 className="text-center mb-5">{currentSemester} Registered Modules</h4>
                 <div className="row">
                     <div className="col-2"><b>Student ID:</b></div>
                     <div className="col-4">{userData.StudentID}</div>
                     <div className="col-2"><b>Faculty:</b></div>
                     <div className="col-4">{userData.StudentFaculty}</div>
                 </div>
                 <div className="row">
                     <div className="col-2"><b>Names:</b></div>
                     <div className="col-4">{userData.StudentName}</div>
                     <div className="col-2"><b>Department:</b></div>
                     <div className="col-4">{userData.StudentCourse}</div>
                 </div>
                 <div className="row mb-5">
                     <div className="col-2"><b>Level:</b></div>
                     <div className="col-4">{userData.StudentLevel}</div>
                     <div className="col-2"><b>Semester:</b></div>
                     <div className="col-4">{userData.StudentSemester}</div>
                 </div>
                            <table className="mb-5" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <td><strong>S/NO</strong></td>
                                    <td width={"10%"}><strong>Module Code</strong></td>
                                    <td><strong>Module Description</strong></td>
                                    <td><strong>Credit Load</strong></td>
                                    <td width={"10%"}><strong>Semester</strong></td>
                                    <td><strong>Level</strong></td>
                                    <td><strong>Status</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    moduleSemesterList.length > 0 ?
                                        <>
                                            {
                                                moduleSemesterList.map((x, y) => {
                                                    return (
                                                        <tr key={y}>
                                                            <td>{y+1}</td>
                                                            <td>{x.ModuleCode}</td>
                                                            <td>{x.ModuleName}</td>
                                                            <td>{x.CreditLoad}</td>
                                                            <td>{x.StudentSemester}</td>
                                                            <td>{x.StudentLevel}</td>
                                                            <td>{x.Status}</td>
                                                        </tr>
                                                        
                                                    )
                                                })
                                            }
                                        </>
                                        : <></>
                                        
                                }
                            <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    
                                    <td><strong>Total Credit Registered</strong></td>
                                    <td><strong>
                                        {
                                            total 
                                        }</strong>
                                    </td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                    <div className="offset-1">
                    <button id="printPageButton" onClick={printNow} className="btn btn-secondary">Print <i className="bi-printer"/></button>
                    </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(PrintModule);
